<template>
	<div>
		<div class="calendar-actions">
			<a class="btn text" @click.prevent="prev" href="#"
				><i class="fal fa-fw fa-angle-left"></i
			></a>
			<a class="btn text" @click.prevent>{{ month }} / {{ year }}</a>
			<a class="btn text" @click.prevent="next" href="#"
				><i class="fal fa-fw fa-angle-right"></i
			></a>
		</div>
		<div class="calendar">
			<div class="week">
				<div class="day"><span>M</span></div>
				<div class="day"><span>T</span></div>
				<div class="day"><span>W</span></div>
				<div class="day"><span>T</span></div>
				<div class="day"><span>F</span></div>
				<div class="day"><span>S</span></div>
				<div class="day"><span>S</span></div>
			</div>
			<div class="week" v-for="(week, w) in weeks" :key="`week_${w}`">
				<a
					href="#"
					@click.prevent="() => select(day)"
					:class="['day', { weekday: day.weekday, today: day.today, 'in-range': day.inRange }]"
					v-for="day in week"
					:key="`day_${day.date}`"
				>
					<span>{{ day.date }}</span>
					<Card
						v-for="(item, i) in day.items"
						:key="`data ${day.date}_${i}`"
						:class="[
							'condensed xs elevation-1 card-v text',
							{  },
						]"
						:name="item.name"
						theme="good"
					/>
				</a>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import dayjs from "dayjs";
import { mod } from "../helpers/math";
import Card from "./Card";
import { isScheduled } from "../helpers/calendar";

export default {
	components: { Card },
	props: {
		context: String,
		rangeFrom: {},
		rangeTo: {},
		rangeData: Object,
		rangeRepeat: Boolean
	},
	data: () => ({
		date: null,
		today: {},
		month: 0,
		year: 0,
		weeks: [],
		days: [],
	}),
	computed: {
		...mapGetters({
			schedules: "schedules",
			plans: "plans/data",
			tasks: "tasks/data",
			tags: "tags/data",
			habits: "habits/data",
		}),
	},
	watch: {
		rangeFrom() {
			this.mapMonth();
		},
		rangeTo() {
			this.mapMonth();
		},
		"schedules.list"() {
			this.mapMonth();
		}
	},
	methods: {
		...mapActions({
			loadAll: "loadAll",
		}),
		next() {
			this.month++;
			if (this.month > 12) {
				this.year++;
				this.month = 1;
			}
			this.mapMonth();
		},
		prev() {
			this.month--;
			if (this.month < 1) {
				this.year--;
				this.month = 12;
			}
			this.mapMonth();
		},
		mapMonth() {
			const monthStart = dayjs(`${this.year}-${this.month}-01`);
			const monthDays = monthStart.daysInMonth();
			this.date = monthStart.format("DD/MM/YYYY");

			this.weeks = [];
			this.days = [];

			// previous month
			const daysPrev = monthStart.day() - 1;
			for (var bi = 0; bi < daysPrev; bi++) {
				const d = monthStart.add(bi - daysPrev, "day");
				const date = d.date();
				const day = d.day();
				const unix = d.unix();
				const today = this.today.unix == unix;

				let inRange = false;
				if (this.rangeFrom != null)
				{
					if (unix == this.rangeFrom)
						inRange = true;
					else if (this.rangeTo != null && unix >= this.rangeFrom && unix <= this.rangeTo)
						inRange = true;
				}

				this.days.push({
					date,
					day,
					month: d.month(),
					weekday: day >= 1 && day <= 5,
					today,
					items: [],
					unix,
					inRange
				});
			}

			// this month
			for (var i = 0; i < monthDays; i++) {
				const d = monthStart.add(i, "day");
				const date = d.date();
				const day = d.day();
				const unix = d.unix();
				const today = this.today.unix == unix;

				let inRange = false;
				if (this.rangeFrom != null)
				{
					if (unix == this.rangeFrom)
						inRange = true;
					else if (this.rangeTo != null && unix >= this.rangeFrom && unix <= this.rangeTo)
						inRange = true;
				}

				const year = d.year();
				const month = d.month() + 1;
				const weekday = day >= 1 && day <= 5;
				const items = this.schedules.list.filter(x => isScheduled({ unix, date, weekday: mod(day-1, 7), month, year }, x)).map(x => ({
					name: x.name
				}));

				this.days.push({
					date,
					day,
					month,
					weekday,
					today,
					items,
					unix,
					inRange
				});
			}

			// fill days in next month
			const daysAfter = 7 - mod(daysPrev + monthDays - 1, 7);
			for (var ai = 0; ai < daysAfter; ai++) {
				const d = monthStart.add(monthDays + ai, "day");
				const date = d.date();
				const day = d.day();
				const unix = d.unix();
				const today = this.today.unix == unix;

				let inRange = false;
				if (this.rangeFrom != null)
				{
					if (unix == this.rangeFrom)
						inRange = true;
					else if (this.rangeTo != null && unix >= this.rangeFrom && unix <= this.rangeTo)
						inRange = true;
				}

				this.days.push({
					date,
					day,
					month: d.month() + 2,
					weekday: day >= 1 && day <= 5,
					today,
					items: [],
					unix,
					inRange
				});
			}

			// populate weeks
			let j = 0;
			let week = [];
			this.weeks = [];
			this.days.forEach((day) => {
				week.push(day);

				if (j % 7 == 6) {
					this.weeks.push(week);
					week = [];
				}
				j++;
			});
		},
		select(day) {
			const data = dayjs(`${this.year}-${this.month}-${day.date}`);

			if (this.context == "select-from") {
				this.$emit("select-from", data);
			} else if (this.context == "select-to") {
				this.$emit("select-to", data);
			}
			else {
				this.$emit("select", { ...day, data });
			}
		},
	},
	async created() {
		await this.loadAll();

		const now = new Date(Date.now());

		this.month = now.getMonth() + 1;
		this.year = now.getFullYear();
		this.today = {
			date: now.getDate(),
			month: this.month,
			year: this.year,
			unix: new Date(this.year, this.month - 1, now.getDate(), 0, 0, 0).getTime() / 1000
		};
		this.mapMonth();
	},
};
</script>
