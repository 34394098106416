const SECONDS_IN_DAY = 86400;
const SECONDS_IN_WEEK = 604800;

export const isScheduled = ({ unix, date, weekday, month, year }, container) => {
	for (var key in container.defs) {
		const schedule = container.defs[key];

		// lower bound
		if (unix < schedule.from) continue;

		// upper bound
		if (schedule.to != null && unix > schedule.to) continue;

		if (schedule.frequencyIndex == 0) {
			// day
			if (
				(unix / SECONDS_IN_DAY - schedule.from / SECONDS_IN_DAY) %
					schedule.frequencyInterval ==
				0
			)
				return true;
		} else if (schedule.frequencyIndex == 1) {
			// week
			if (
				Math.floor((unix / SECONDS_IN_WEEK) - (schedule.from / SECONDS_IN_WEEK)) %
					schedule.frequencyInterval != 0
			)
				continue;

			if (!schedule.frequencyMeta.weekDays)
			{
				if (weekday == schedule.fromWeekday)
					return true;
			}
			else 
			{
				if (schedule.frequencyMeta.weekDays.includes(weekday))
					return true;
			}
		} else if (schedule.frequencyIndex == 2) {
			// month
			const yearDiff = year - schedule.fromYear;
			const monthOffset = yearDiff * 12;
			if (
				(month + monthOffset - schedule.fromMonth - 1) %
					schedule.frequencyInterval !=
				0 && date == schedule.fromDate
			)
				return true;
		} else if (schedule.frequencyIndex == 3) {
			// year
			if ((year - schedule.fromYear) % schedule.frequencyInterval != 0 && date == schedule.fromDate)
				return true;
		}
	}
	return false;
};
