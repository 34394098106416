<template>
	<div>
		<transition name="dialog-anim">
			<div
				v-show="active && context"
				class="dialog designer-sequence active"
			>
				<div class="dialog-heading">{{ name }}</div>
				<div class="dialog-body">
					<TextInput label="Sequence Name" v-model="params.name" />

					<Card
						name="Actions"
						class="heading"
						:fnClick="() => (showActions = !showActions)"
						:actions="[
							{
								fnClick: () =>
									pushDialog(dialogKeys.SEQUENCE_ACTIONS),
								icon: 'plus',
							},
							{
								fnClick: () => (showActions = !showActions),
								icon: showActions ? 'angle-down' : 'angle-right',
								badge: !showActions && params.actions.length.toString()
							},
						]"
					/>

					<transition>
						<div class="indent" v-if="showActions">
							<div class="menu">
								<div>
									<table
										@touchmove="(e) => loopDragMove(e)"
										@touchend="(e) => loopDragDrop(e)"
									>
										<tr
											v-for="(action, i) in actionData"
											:class="{
												'active--loop':
													i >= loopBounds.from &&
													i <= loopBounds.to,
											}"
											:key="`action_${i}`"
										>
											<td>
												<Card
													:key="`action_${i}`"
													class="card-h elevation-1"
													:name="action.name"
													:icon="
														action.parentRefId
															? 'bars'
															: 'credit-card-blank'
													"
													:fnClick="
														() => open(action)
													"
													:actions="[
														{
															icon: 'trash',
															fnClick: () =>
																removeAction(i),
														},
													]"
													:units="
														action.units.map(
															(unit) => ({
																value: `${
																	unitData[
																		unit
																			.type
																	][unit.key]
																		.prefix
																}${unit.value}${
																	unitData[
																		unit
																			.type
																	][unit.key]
																		.suffix
																}`,
															})
														)
													"
												/>
											</td>

											<td
												v-if="action.loopFrom"
												:rowspan="action.loopLength"
												:class="{
													scope:
														action.loopIndex !=
														null,
												}"
												@click.prevent="
													() =>
														openLoop(
															action.loopIndex
														)
												"
											>
												<span
													>x{{
														params.loops[
															action.loopIndex
														].repeat
													}}</span
												>
											</td>
											<td
												v-if="action.loopIndex == null"
												:class="[
													'loop-handle',
													{
														from:
															loopBounds.from ==
															i,
														to: loopBounds.to == i,
													},
												]"
											>
												<a
													data-type="repeat"
													:data-index="i"
													draggable="false"
													@mousedown.prevent="
														(e) =>
															loopDragStart(i, e)
													"
													@touchstart.prevent="
														(e) =>
															loopDragStart(i, e)
													"
													@mousemove.prevent="
														(e) => loopDragMove(e)
													"
													@mouseup.prevent="
														(e) => loopDragDrop(e)
													"
													href="#"
													@click.prevent="
														() => loopClick(i)
													"
													><i
														class="fal fa-fw fa-repeat-alt"
													></i
												></a>
											</td>
										</tr>
									</table>
								</div>
							</div>
						</div>
					</transition>

					<div class="v-spacer"></div>

					<Card
						class="mt-2 heading"
						name="Tags"
						:fnClick="() => (showTags = !showTags)"
						:actions="[
							{
								icon: 'plus',
								fnClick: () => pushDialog('sequence-add-tag')
							},
							{
								fnClick: () => (showTags = !showTags),
								icon: showTags ? 'angle-down' : 'angle-right',
								badge: !showTags && params.tags.length.toString()
							},
						]"
					/>

					<transition>
						<div class="indent" v-if="showTags">

							<Card
								v-for="(tagId, i) in params.tags"
								:key="`tag_${i}`"
								:name="tags.get(tagId).name"
								class="light"
								:actions="[
									{
										fnClick: () =>
											params.tags.splice(i, 1),
										icon: 'trash',
									},
								]"
							/>

						</div>
					</transition>

					<div class="v-spacer"></div>

					<Card
						name="Schedules"
						class="mt-2 heading"
						:fnClick="() => showSchedules = !showSchedules"
						:actions="[
							{
								fnClick: () => openAddSchedule(),
								icon: 'plus',
							},
							{
								fnClick: () => (showSchedules = !showSchedules),
								icon: showSchedules ? 'angle-down' : 'angle-right',
								badge: !showSchedules && params.schedules.length.toString()
							},
						]"
					/>

					<div v-if="showSchedules" class="indent">
					<Card
						v-for="(schedule,i) in params.schedules"
						:key="`sequence-schedule_${i}`"
						:fnClick="() => openUpdateSchedule(i)"
						:name="schedule.name"
						class="card-h elevation-1 light mt-2"
						:state="schedule.desc"
						:actions="[
							{
								fnClick: () => removeSchedule(i),
								icon: 'trash'
							}
						]"
						/>
					</div>

					<div class="dialog-actions">
						<Card name="Save" icon="save" class="button" :fnClick="save" />
					</div>
				</div>
			</div>
		</transition>

		<SideMenu :dialogKey="dialogKeys.SEQUENCE_ACTIONS">
			<div class="dialog-heading">Add Action</div>
			<div class="dialog-body">
				<Card
					class="heading"
					name="From Sequence"
					:actions="[{ icon: 'plus', fnClick: () => createInline() }]"
				/>
				<Card
					v-if="inlineData.length == 0"
					class="active border"
					name="None defined"
					icon="info-circle"
					theme="default"
				/>

				<Card
					v-for="(action, i) in inlineData"
					:key="`inlineActions_${i}`"
					class="light"
					:name="action.name"
					:fnClick="() => addAction(action.refId)"
				/>

				<Card class="heading" name="From Tasks (Single Action)" />

				<Card
					v-for="(action, i) in taskActions"
					:key="`taskActions_${i}`"
					class="light"
					:name="action.name"
					:fnClick="() => addAction(action.refId)"
				/>
			</div>
		</SideMenu>

		<SideMenu :dialogKey="dialogKeys.SEQUENCE_LOOP">
			<div class="dialog-heading">Edit Loop</div>
			<div v-if="loopEditContext" class="dialog-body">
				<TextInput
					label="Repeat"
					type="number"
					v-model="loopEditContext.repeat"
				/>
				<div class="dialog-actions">
					<button @click.prevent="removeLoop">Remove</button>
					<button @click.prevent="applyLoop">Apply</button>
				</div>
			</div>
		</SideMenu>
		
		<SideMenu dialogKey="sequence-add-tag">
			<div class="dialog-heading">Add Tag</div>
			<div class="dialog-body">
				<Card
					v-for="(tag,i) in tags.list.filter(x => !params.tags.includes(x.refId))"
					:key="`add-tag_${i}`"
					:name="tag.name"
					:fnClick="() => {
						params.tags.push(tag.refId);
						showTags = true;
						popDialog();
					}"
					/>
			</div>
		</SideMenu>

	</div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { TextInput } from "../inputs/index";
import SideMenu from "../menus/SideMenu";
// import { v4 as uuid } from "uuid";
import { clone, isnull } from "../../helpers/objects";

import {
	SEQUENCE,
	SEQUENCE_ACTIONS,
	SEQUENCE_LOOP,
	ACTION,
} from "../../data/dialog-keys";

import Card from "../Card";

const getTargetFromDrag = (e) => {
	if (e.changedTouches != undefined) {
		return document
			.elementFromPoint(
				e.changedTouches[0].pageX,
				e.changedTouches[0].pageY
			)
			.closest("[data-type=repeat]");
	} else {
		return e.target.closest("[data-type=repeat]");
	}
};

const defaultParams = {
	name: "",
	actions: [],
	loops: [],
	inlineActions: {},
	positive: true,
	tags: [],
	schedules: []
}

export default {
	components: {
		TextInput,
		SideMenu,
		Card
	},
	watch: {
		context(ctx) {
			if (ctx) this.copyData(ctx);
		},
	},
	data: () => ({
		params: clone(defaultParams),
		actionData: [],
		loopCreate: null,
		loopBounds: { from: -1, to: -1 },
		lastDragIndex: -1,
		loopEditIndex: -1,
		loopEditContext: null,

		showActions: true,
		showTags: false,
		showSchedules: false
	}),
	computed: {
		...mapGetters({
			context: "tasks/sequenceContext",
			dialog: "dialog",
			tasks: "tasks/data",
			taskActions: "tasks/actions",
			unitData: "unitData",
			tags: "tags/data",
		}),
		dialogKeys: () => ({
			SEQUENCE,
			SEQUENCE_ACTIONS,
			ACTION,
			SEQUENCE_LOOP,
		}),
		active() {
			return this.dialog.current == SEQUENCE;
		},
		name() {
			if (!this.context) return "";
			if (!this.context.refId) return "New Sequence";
			return `Edit '${this.context.name}'`;
		},
		inlineData() {
			return Object.keys(this.params.inlineActions).map((key) => ({
				key,
				...this.params.inlineActions[key],
			}));
		},
	},
	methods: {
		...mapActions({
			pushDialog: "pushDialog",
			popDialog: "popDialog",
			success: "broadcastSuccess",
			setActionContext: "tasks/setActionContext",
			saveSequenceContext: "tasks/saveSequenceContext",
			openSchedule: "openSchedule",
			registerAction: "registerAction"
		}),
		handleActionGet() {},
		handleActionCreate() {},
		handleActionUpdate() {},
		copyData(data) {
			this.params.name = data.name || "";
			this.params.actions = clone(data.actions || []);
			this.params.loops = clone(data.loops || []);
			this.params.inlineActions = clone(data.inlineActions || {});
			this.params.positive = isnull(data.positive, true);
			this.params.tags = clone(data.tags || []);
			this.params.schedules = clone(data.schedules || []);
			this.mapActionData();
		},
		mapActionData() {
			this.actionData = this.params.actions
				.map((x) => {
					if (x.refId) return this.tasks.getActionFromRefId(x.refId);

					if (x.entityId) return this.tasks.getFromId(x.entityId);

					if (x.inlineId) return this.inlineActions[x.inlineId];

					return null;
				})
				.filter((x) => x);
			this.mapActionLoopData();
		},
		mapActionLoopData() {
			this.actionData = this.actionData.map((x) => ({
				...x,
				loopIndex: null,
				loopFrom: false,
				loopLength: 1,
			}));

			this.params.loops.forEach((l, i) => {
				for (var j = l.from; j <= l.to; j++) {
					this.actionData[j].loopIndex = i;
					this.actionData[j].loopFrom = j == l.from;
					this.actionData[j].loopTo = j == l.to;
					this.actionData[j].loopLength = l.to - l.from + 1;
				}
			});
		},

		save() {
			this.saveSequenceContext(this.params);
			this.popDialog();
		},

		addAction(refId) {
			this.params.actions.push({ refId });
			this.mapActionData();
			this.popDialog();
		},
		removeAction(index) {
			const loopIndex = this.actionData[index].loopIndex;
			if (loopIndex != null) {
				const loop = this.params.loops[loopIndex];
				if (loop.to == loop.from)
					this.params.loops.splice(loopIndex, 1);
				else loop.to--;
			}
			this.params.actions.splice(index, 1);
			this.mapActionData();
		},

		inActiveLoopBounds(index) {
			if (this.actionData.length <= index) return false;

			return this.actionData[index].loopIndex != null;
		},
		getOverLoopBounds() {
			if (!this.loopCreate) return { from: -1, to: -1 };
			if (this.loopCreate.to == null) return { from: -1, to: -1 };

			const points = [this.loopCreate.from, this.loopCreate.to];
			return { from: Math.min(points), to: Math.max(points) };
		},
		loopClick(index) {
			this.loopDragStart(index);
			this.loopDragEnter(index);
			this.loopDragDrop(index);
			this.loopDragEnd();
		},
		loopDragStart(index, e) {
			e.preventDefault();
			e.stopPropagation();

			if (this.actionData[index].loopIndex != null) {
				// loop exists
				return;
			}

			this.loopCreate = { from: index, to: null };
			this.loopBounds = { from: -1, to: -1 };
		},
		loopDragMove(e) {
			if (this.loopCreate == null) return;

			let target = getTargetFromDrag(e);

			if (!target) return;

			const index = target.dataset.index;

			if (this.lastDragIndex == index) return;

			this.lastDragIndex = index;

			this.loopDragEnter(index);
		},
		loopDragEnter(index) {
			let from = 0;
			let to = 0;

			if (this.loopCreate.from <= index) {
				from = this.loopCreate.from;
				to = index;
			} else {
				from = index;
				to = this.loopCreate.from;
			}
			this.loopBounds = { from, to };

			for (var i = from; i <= to; i++) {
				if (this.actionData[i].loopIndex != null) {
					// collision detected
					return;
				}
			}

			this.loopCreate.to = index;
		},
		loopDragDrop(e) {
			if (this.loopCreate == null || this.loopCreate.to == null) return;

			const target = getTargetFromDrag(e);

			let index = -1;

			if (target) index = target.dataset.index;
			else index = this.lastDragIndex;

			this.loopCreate.to = index;

			let from = 0;
			let to = 0;
			if (this.loopCreate.from <= this.loopCreate.to) {
				from = this.loopCreate.from;
				to = this.loopCreate.to;
			} else {
				from = this.loopCreate.to;
				to = this.loopCreate.from;
			}
			this.loopBounds = { from, to };

			for (var i = from; i <= to; i++) {
				if (this.actionData[i].loopIndex != null) {
					// collision detected
					return;
				}
			}

			const loop = {
				// refId: uuid(),
				from,
				to,
				repeat: 2,
			};

			this.params.loops.push(loop);
			this.params.loops.sort((a, b) => (a.from < b.from ? -1 : 1));
			this.mapActionLoopData();

			this.loopDragEnd();
		},
		loopDragEnd() {
			this.loopCreate = null;
			this.loopBounds = { from: -1, to: -1 };
			this.lastDragIndex = -1;
		},
		openLoop(index) {
			this.loopEditIndex = index;
			this.loopEditContext = { ...this.params.loops[index] };
			this.pushDialog(SEQUENCE_LOOP);
		},
		applyLoop() {
			this.params.loops[
				this.loopEditIndex
			].repeat = this.loopEditContext.repeat;
			this.popDialog();
			this.loopEditIndex = -1;
			this.loopEditContext = null;
		},
		removeLoop() {
			this.params.loops.splice(this.loopEditIndex, 1);
			this.mapActionLoopData();
			this.popDialog();
			this.loopEditIndex = -1;
			this.loopEditContext = null;
		},

		async createInline() {
			const t = this;
			const actionContext = {
				parentRefId: this.context.refId,
			};

			(await this.setActionContext(actionContext)).onSave((action) => {
				this.$set(
					t.params.inlineActions,
					action.refId,
					action
				);
				t.params.actions.push({ refId: action.refId });
				t.mapActionData();
				t.popDialog();
			});
			this.pushDialog(ACTION);
		},
		async openInline(refId) {
			const actionContext = {
				...this.params.inlineActions[refId],
				parentRefId: this.context.refId,
			};

			(await this.setActionContext(actionContext)).onSave((action) => {
				this.params.inlineActions[refId] = clone(action);
				this.mapActionData();
			});

			this.pushDialog(ACTION);
		},
		async openTask(refId) {
			const actionContext = {
				...this.tasks.getActionFromRefId(refId),
				parentRefId: null,
			};

			(await this.setActionContext(actionContext)).onSave(() => {
				this.mapActionData();
			});

			this.pushDialog(ACTION);
		},
		open(action) {
			if (action.parentRefId) this.openInline(action.refId);
			else this.openTask(action.refId);
		},

		
		async openAddSchedule() {
			const t = this;
			const schedule = await this.openSchedule();

			schedule.onSave(data => {
				t.params.schedules.push(data);
				t.showSchedules = true;
				t.popDialog();
			});
		},
		async openUpdateSchedule(index) {
			const t = this;
			const schedule = await this.openSchedule(this.params.schedules[index]);

			schedule.onSave(data => {
				t.params.schedules[index] = data;
				t.showSchedules = true;
				t.popDialog();
			});
		},
		removeSchedule(index) {
			this.params.schedules.splice(index, 1);
		}
	},
};
</script>
